/**
 * A component that provides a selection of sites.
 * Supported props: locations localizerfn header description btnlabel
 */
import React from 'react';
import Safe from './Safe';

export default function LocationsPage(props) {
    const addresses = props.locations;
    const leftCount = Math.ceil(addresses.length / 2);

    return (
        <div id="location-page" className="location-page">
            <Safe className="location-header" content={props.header} />
            <Safe className="location-desc" content={props.description} />
            <div className="locations container-fluid">
                <div className="row">
                    <div className="col-sm-6">
                        <div className="first">
                            {addresses.slice(0, leftCount).map((item, index) => (
                                <div key={`addr_${index}`} className="locations-row my-8">
                                    <div className="row">{renderSite(item)}</div>
                                </div>)
                            )}
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="second">
                            {addresses.slice(leftCount).map((item, index) => {
                                return (
                                    <div key={`addr_${index}`} className="locations-row my-8">
                                        <div className="row">{renderSite(item)}</div>
                                    </div>);
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

function renderSite(site) {
    const callNumber = "tel:" + site.phone;
    return (
        <div className="row">
            <div className="col-sm-12">
                <div className="loc-title"><div><strong>{site.name}</strong></div></div>
                <div className="street-block"><div className="thoroughfare">{site.street}</div></div>
                <div className="addressfield-container-inline locality-block country-US">
                    <span className="locality">{site.city}, </span>
                    <span className="state">{site.state} </span>
                    <span className="postal-code">{site.zip}</span>
                    {site.phone &&
                        <div><a href={callNumber} target="_blank" className="call-link">{site.phone}</a></div>
                    }
                </div>
            </div>
            
        </div>
    );
}
